import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import { from, of, pipe } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as Action from './actions'
import { Router } from '@angular/router';
import { error } from 'console';
import { NotificationService } from 'app/services/dal/notification.service';
import { markAllAsRead } from './actions';
import { environment } from 'environments/environment';
import { Store } from '@ngrx/store';



@Injectable()
export class Effects
// implements OnInitEffects
{



  // ngrxOnInitEffects() {
  //   return Action.loadItemsFromInitEffect;
  // }



  constructor(
    private store: Store,
    private router: Router,
    private actions$: Actions,
    private notificationService: NotificationService,
    private toastService: ToastService) { }

  // loadItems$ = createEffect(() => {
  //   return this.actions$
  //     .pipe(
  //       ofType(
  //         Action.loadItems,
  //         Action.loadItemsFromInitEffect
  //       ),
  //       mergeMap((action) => this.notificationService.getItemsWithTake(action.barId, environment.notificationsCount)
  //         .pipe(
  //           map(items => Action.loadItemsSuccess({ items })),
  //           catchError(error => {
  //             console.error('error', error);

  //             return of(Action.loadItemsFailure({ error }))
  //           }
  //           )
  //         )
  //       )
  //     );
  // });

  loadReadItems$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          Action.loadReadItems,
        ),
        mergeMap((action) => this.notificationService.getReadItemsWithTake(action.barId, environment.notificationsCount)
          .pipe(
            map(items => Action.loadItemsSuccess({ items })),
            catchError(error => {
              console.error('error', error);

              return of(Action.loadItemsFailure({ error }))
            }
            )
          )
        )
      );
  });

  loadUnreadItems$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          Action.loadUnreadItems
        ),
        mergeMap((action) => this.notificationService.getUnreadItemsWithTake(action.barId, environment.notificationsCount)
          .pipe(
            map(items => Action.loadUnreadItemsSuccess({ barId: action.barId, items })),
            catchError(error => {
              console.error('error', error);

              return of(Action.loadItemsFailure({ error }))
            }
            )
          )
        )
      );
  });


  loadUnreadItemsAfter$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          Action.loadUnreadItemsAfter,
        ),
        mergeMap((action) => from(this.notificationService.getUnreadItemsWithTakeFrom(action.barId, 15, action.startAfter))
          .pipe(
            map(items => Action.loadItemsAfterSuccess({ items })),
            catchError(error => {
              console.error('error', error);

              return of(Action.loadItemsFailure({ error }))
            }
            )
          )
        )
      );
  });

  loadReadItemsAfter$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          Action.loadReadItemsAfter,
        ),
        mergeMap((action) => from(this.notificationService.getReadItemsWithTakeFrom(action.barId, 15, action.startAfter))
          .pipe(
            map(items => Action.loadItemsAfterSuccess({ items })),
            catchError(error => {
              console.error('error', error);

              return of(Action.loadItemsFailure({ error }))
            }
            )
          )
        )
      );
  });

  loadAllItemsAfter$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          Action.loadAllItemsAfter,
        ),
        mergeMap((action) => from(this.notificationService.getAllItemsWithTakeFrom(action.barId, 15, action.startAfter))
          .pipe(
            map(items => Action.loadItemsAfterSuccess({ items })),
            catchError(error => {
              console.error('error', error);

              return of(Action.loadItemsFailure({ error }))
            }
            )
          )
        )
      );
  });

  setItemFromListPage$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(Action.setItemFromListPage),
          tap((action) => {
            this.router.navigate(['/notifications/tabs/details', action.id]);
          }),
        )
    },
    { dispatch: false }
  );


  update$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.updateItem),
        mergeMap((action) => of(this.notificationService.update(action.item))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return Action.updateItemSuccess({ item: action.item });
            }),
            catchError(error => of(Action.updateItemFailure({ error })))
          )
        )
      );
  });

  markVisibleAsRead$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.markAllVisibleAsRead),
        mergeMap((action) => of(this.notificationService.markAllVisibleAsRead(action.items))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return Action.markAllAsReadSuccess();
            }),
            catchError(error => of(Action.markAllAsReadFailure({ error })))
          )
        )
      );
  });

  markAsRead$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.markAllAsRead),
        mergeMap((action) => of(this.notificationService.markAllTapAsRead(action.barId))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return Action.markAllAsReadSuccess();
            }),
            catchError(error => of(Action.markAllAsReadFailure({ error })))
          )
        )
      );
  });


  deleteAllReadTapNotifications$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.deleteAllReadTapNotifications),
        mergeMap((action) => of(this.notificationService.deleteAllReadTapNotifications(action.barId))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return Action.deleteAllReadTapNotificationsSuccess();
            }),
            catchError(error => of(Action.deleteAllReadTapNotificationsFailure({ error })))
          )
        )
      );
  });


  deleteAllTapNotifications$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.deleteAllTapNotifications),
        mergeMap((action) => of(this.notificationService.deleteAllTapNotifications(action.barId))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return Action.deleteAllReadTapNotificationsSuccess();
            }),
            catchError(error => of(Action.deleteAllTapNotificationsFailure({ error })))
          )
        )
      );
  });



  add$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.addItem),
        mergeMap((action) => of(this.notificationService.add(action.item, action.id))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return Action.addItemSuccess({ item: action.item });
            }),
            catchError(error => of(Action.addItemFailure({ error })))
          )
        )
      );
  });


  delete$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.deleteItem),
        mergeMap((action) => of(this.notificationService.delete(action.id))
          .pipe(
            map(() => {
              this.toastService.deleteItemSuccess();
              return Action.deleteItemSuccess({ id: action.id, route: action.route });
            }),
            catchError(error => of(Action.addItemFailure({ error })))
          )
        )
      );
  });

  deleteSuccess$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(Action.deleteItemSuccess),
          tap((action) => {
            this.router.navigate([action.route]);
          }),
        )
    },
    { dispatch: false }
  );


  setRead$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.setRead),
        mergeMap((action) => from(this.notificationService.softUpdate(action.id, { read: true }, action.uid))
          .pipe(
            map(() => {
              return Action.setReadSuccess({ id: action.id })
            }),
            catchError((error) => {
              return of(Action.setReadFailure({ error }))
            })
          )
        )
      );
  });

  softUpdate$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.softUpdate),
        mergeMap((action) => from(this.notificationService.softUpdate(action.id, action.data, action.uid))
          .pipe(
            map(() => {
              return Action.softUpdateSuccess({ id: action.id, data: action.data })
            }),
            catchError((error) => {
              return of(Action.softUpdateFailure({ error }))
            })
          )
        )
      );
  });

  softUpdateSuccess$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.softUpdateSuccess),
        tap(() => {
          this.toastService.saveSuccess();
        }),
      );
  }, { dispatch: false });


  softUpdateFailure$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.softUpdateFailure),
        tap(({ error }) => {
          this.toastService.error(JSON.stringify(error));
        })
      );
  }, { dispatch: false });



}
